import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["currentCard", "header"];

  connect() {
    this.scrollToCurrentCard();
  }

  scrollToCurrentCard() {
    if (this.hasCurrentCardTarget && this.hasHeaderTarget) {

      const sidebar = this.element;
      const cardTop = this.currentCardTarget.offsetTop;
      const headerHeight = this.headerTarget.offsetHeight;
      const scrollTopValue = cardTop - headerHeight - 56; // went w/ 3.5rem here, it ensures the first card doesn't scroll strangely and provides space to denote other cards when scrolled down the page

      sidebar.scrollTop = scrollTopValue;
    }
  }
}