import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import { setInnerHtmlWithTimeout } from "../lib/util";

export default class extends Controller {
  static targets = ["slider", "toggleButton", "toggleUpdateMessage", "input"];

  static values = {
    path: String,
    renderStreamResponse: Boolean,
    behavior: String
  };

  sliderTargetConnected() {
    this.updateToggle(this.getEnabled());
  }

  /**
   * actions
   */

  toggle() {
    const newSetting = !this.getEnabled();

    this.setEnabled(newSetting);
    this.updateToggle(newSetting);

    switch (this.behaviorValue) {
      case "on_off_api":
        const payload = newSetting ?
          JSON.parse(this.element.getAttribute("data-on-off-api-on-payload")) :
          JSON.parse(this.element.getAttribute("data-on-off-api-off-payload"));

        this.sendToApi(
          payload
        );

        break;

      case "form":
        this.inputTarget.setAttribute("value", newSetting);
        break;

      case "default":
        this.sendToApi({ value: newSetting });
        break;
    }
  }

  /**
   * helper methods
   */

  updateToggle(newSetting) {

  }

  slideToTrue() {
    // change background color

  }

  slideToFalse() {
    // change background color

  }

  async sendToApi(payload) {
    const fetchParams = {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    };

    if (this.shouldRenderStreamResponse())
      fetchParams.headers["Accept"] = "text/vnd.turbo-stream.html";
    else
      fetchParams.headers["Accept"] = "application/json";

    try {
      const res = await fetch(this.pathValue, fetchParams);

      if (this.shouldRenderStreamResponse())
        Turbo.renderStreamMessage(await res.text());

      if (!res.ok)
        throw new Error();

    } catch (error) {
      // This value was just set to the user's intention; we want
      // the UI and DOM-stored value to now reflect the opposite
      const recentlySetValue = (this.sliderTarget.getAttribute("aria-checked") == "true");
      this.sliderTarget.setAttribute("aria-checked", `${!recentlySetValue}`);
      this.updateToggle(!recentlySetValue);
      this.setInvitationLinkUpdateMessage("<p class='text-crimson-400 text-xs'>Failed</p>");
    }
  }

  setInvitationLinkUpdateMessage(html) {
    if (this.lastTimeout) {
      clearTimeout(this.lastTimeout);
    }

    this.lastTimeout = setInnerHtmlWithTimeout(
      this.toggleUpdateMessageTarget,
      html,
      1500
    );
  }

  getEnabled() {
    return this.sliderTarget.getAttribute("aria-checked") == "true";
  }

  setEnabled(enabled) {
    this.sliderTarget.setAttribute("aria-checked", enabled.toString());
  }

  shouldRenderStreamResponse() {
    return this.renderStreamResponseValue == true;
  }
}
