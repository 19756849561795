
import Rails from "@rails/ujs";

export type CreateResponse = {
  success: boolean;
  data?: {url: string};
};

export async function create(): Promise<CreateResponse> {
  const url = "/subscriptions/manage";

  const res = await fetch(url, {
    method: "GET",
    headers: {
      "X-CSRF-Token": Rails.csrfToken(),
      "Content-Type": "application/json",
      "Accept": "application/json",
      "HTTP_USER_AGENT": "Turbo Native"
    }
  });

  if (res.ok) {
    const data = await res.json();
    return {success: true, data: {url: data.url}};
  }

  throw new Error(`Unable to get billing portal URL. Server response: ${res.status} '${await res.text()}'`);
}
