/**
 * Client side behavior for the video player
 */

import {Controller} from "@hotwired/stimulus";
import PlayerBehavior from "../lib/lessons/player_behavior";
import MuxPlayerAdapter from "../lib/lessons/mux_player_adapter";
import VimeoPlayerAdapter from "../lib/lessons/vimeo_player_adapter";
import CloudinaryPlayerAdapter from "../lib/lessons/cloudinary_player_adapter";
import * as Util from "../lib/util";

export default class extends Controller {
  static targets = ["player"];

  static values = {
    providerName: String,
    providerId: String,
    lessonHexId: String,
    userHexId: String,
    pickupPosition: Number,
    completionPosition: Number,
    duration: Number,
    isPreview: Boolean
  };

  /**
  * lifecycle
  */

  connect() {
    // initialize the adapter
    this.getPlayerAdapter();
    this.behavior = this.createPlayerBehavior();
    this.getPlayerAdapter().onPlay(() => this.behavior.handlePlay());
    this.getPlayerAdapter().onTimeupdate(seconds => this.behavior.handleTimeUpdate(seconds));

    if (this.isPreviewValue) {
      this.behavior.onPreviewEnd(() => this.showPreviewEnd());
    } else {
      this.behavior.setPlayerPositionToPickupPosition();
      this.getPlayerAdapter().play();
    } 
  }

  /**
  * actions
  */

  handleDocumentKeydown(event) {
    if (this.isPreviewValue)
      return;

    if (
      event.code == "Space" &&
      !Util.alternateUiShown() &&
      document.activeElement.nodeName != "TEXTAREA" &&
      document.activeElement.nodeName != "INPUT"
    ) {
      /**
       * NOTE we don't want this if statement to run if we have an
       * 'alternate' UI open (eg modal / cmd palette) so that input
       * fields in those ui elements can receive spaces on the lesson
       * show page.
       */

      const shouldToggle = !this.playerAdapter.handlesSpaceBarEvents() || (
        this.playerAdapter.handlesSpaceBarEvents() &&
        !this.playerAdapter.isFocused()
      );

      if (shouldToggle) {
        this
          .getPlayerAdapter()
          .togglePlayState();
        
        event.preventDefault();
      }
    }
  }

  /**
   * helpers
   */

  showPreviewEnd() {
    document.querySelectorAll("[data-preview-end-remove=true]").forEach(elementToRemove => {
      elementToRemove.remove();
    });

    document.querySelectorAll("[data-preview-end-show=true]").forEach(elementToShow => {
      elementToShow.classList.toggle("hidden", false);
    });
  }

  getPlayerAdapter() {
    if (!this.playerAdapter)
      this.playerAdapter = this.createPlayerAdapter();

    return this.playerAdapter;
  }

  createPlayerAdapter() {
    const provider = this.providerNameValue;

    switch(provider) {
    case "vimeo":
      return new VimeoPlayerAdapter(this.providerIdValue);

    case "mux":
      return new MuxPlayerAdapter(this.playerTarget);

    case "cloudinary":
      const version = this.element.getAttribute("data-version");
      const cloudName = this.element.getAttribute("data-cloud-name");

      return new CloudinaryPlayerAdapter(
        this.playerTarget,
        this.providerIdValue,
        version,
        cloudName
      );

    default:
      throw new Error(`Unknown provider ${provider}`);
    }
  }

  createPlayerBehavior() {
    return new PlayerBehavior({
      duration: this.durationValue,
      userHexId: this.userHexIdValue,
      lessonHexId: this.lessonHexIdValue,
      pickupPosition: this.pickupPositionValue,
      completionPosition: this.completionPositionValue,
      playerAdapter: this.getPlayerAdapter(),
      isPreview: this.isPreviewValue
    });
  }
}
 