
import {Controller} from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import {setInnerHtmlWithTimeout} from "../../lib/util";

export default class extends Controller {
  static targets = ["invitationLink", "invitationLinkUpdateMessage"];

  /**
   * actions 
   */

  toggleInvitationLink(e) {
    const slider = e.target;
    const toggleButton = slider.querySelector("[data-switch-role=toggle-button]");
    const checked = slider.getAttribute("aria-checked");

    if (checked == "true") {
      // change background color
      slider.classList.toggle("dark:bg-action-600", false);
      slider.classList.toggle("dark:bg-concert-500", true);

      // move toggle button
      toggleButton.classList.toggle("translate-x-5", false);
      toggleButton.classList.toggle("translate-x-0", true);

      // set attributes
      slider.setAttribute("aria-checked", "false");

      // perform request (set is_active to false)
      this.updateInvitationLink(false);

    } else {
      // change background color
      slider.classList.toggle("dark:bg-action-600", true);
      slider.classList.toggle("dark:bg-concert-500", false);

      // move toggle button
      toggleButton.classList.toggle("translate-x-5", true);
      toggleButton.classList.toggle("translate-x-0", false);

      // set attributes
      slider.setAttribute("aria-checked", "true");

      // perform request (set is_active to true)
      this.updateInvitationLink(true);
    }
  }

  copyInvitationLink(e) {
    const link = this.invitationLinkTarget.innerText;
    const button = e.target;

    navigator.clipboard.writeText(link)
      .then(() => {
        button.innerText = "Copied!";

        if (this.lastCopyButtonTimeout) {
          clearTimeout(this.lastCopyButtonTimeout);
        }

        this.lastCopyButtonTimeout = setTimeout(() => {
          button.innerText = "Copy link";
        }, 2000);
      })
      .catch(error => {
        console.error(`unable to copy invitation link to clipboard: ${error}`);
      
        button.innerText = "Error!";

        if (this.lastCopyButtonTimeout) {
          clearTimeout(this.lastCopyButtonTimeout);
        }

        this.lastCopyButtonTimeout = setTimeout(() => {
          button.innerText = "Error!";
        }, 2000);
      });
  }

  /**
   * helper methods
   */

  updateInvitationLink(active) {
    const payload = {
      invitation: {
        is_active: active
      }
    };

    fetch("/settings/team/invitation", {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken()
      },
      body: JSON.stringify(payload)
    })
      .then(res => {
        if (res.ok) {
          return Promise.resolve();
        }

        return Promise.reject();
      })
      .then(() => {
        this.setInvitationLinkUpdateMessage(
          "<p class=\"dark:text-concert-400 text-sm\">Saved</p>"
        );
      })
      .catch(() => {
        this.setInvitationLinkUpdateMessage(
          "<p class=\"dark:text-red-400 text-sm\">Error</p>"
        );
      });
  }

  setInvitationLinkUpdateMessage(html) {
    if (this.lastTimeout) {
      clearTimeout(this.lastTimeout);
    }

    this.lastTimeout = setInnerHtmlWithTimeout(
      this.invitationLinkUpdateMessageTarget,
      html,
      3000
    );
  }
}
