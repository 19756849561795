/**
 * ************* DEPRACATED ****************
 * Don't use this controller. Use new_modal_controller instead
 * *****************************************
 * 
 * NOTE: This controller is a 'global' controller on the body that moves modal contents into a modal
 * context at the top of the body. this is done by cloning the contents and moving the cloned version.
 * If you're having trouble with forms, see below.
 * 
 * NOTE: If you have a modal inside a form (in the case of confirmed submit buttons), place an attribute
 * on the submit button in the modal called 'data-submit-form-with-action' and set it to the action
 * of the form you want to submit. This will add a click listener that queries for the form by the
 * action attribute and clicks the original submit button in the modal contents. This is necessary because
 * calling the .submit() on the form element does not submit the request as a turbo request.
 */

import {Controller} from "@hotwired/stimulus";
import Modal from "../lib/modal";

/**
 * ************* DEPRACATED ****************
 * Don't use this controller. Use new_modal_controller instead
 * *****************************************
 */ 
export default class extends Controller {
  static targets = ["overlay", "container", "content", "forceCloseOnAppear", "overlayBackground"];

  containerTargetConnected() {
    document.addEventListener("click", event => {
      const state = new Modal.State();
      const target = event.target;
      const shown = this.element.getAttribute("data-modal-shown") == "true";

      if (!event.composedPath().includes(state.container) &&
          !this.isIgnoredElement(target) &&
          shown
      ) {
        this.hide();
      }
    });
  }

  forceCloseOnAppearTargetConnected() {
    this.hide();
  }

  show(event) {
    const modalId = event.currentTarget.getAttribute("data-modal-id");
    Modal.show(modalId);
    event.preventDefault();
  }

  hide() {
    Modal.hide();
  }

  keyHide(event) {
    if (event.key == "Escape" && this.element.getAttribute("data-modal-shown") == "true") {
      event.preventDefault();
      this.hide();
    }
  }

  /**
   * Returns true if the element should be ignored. Used to determine if the modal should
   * close on click-off for a given document click event.
   */
  isIgnoredElement(element) {
    const isTrixFileInput = element.id?.match("trix-file-input")?.length > 0;

    let isShowButton = false;
    let currentElement = element;

    while (!isShowButton && currentElement.parentElement) {
      /**
       * Need to recursively walk up the tree to determine if this element is
       * a modal show button OR is _inside_ a modal show button
       */

      isShowButton = currentElement.dataset.action != null ?
        currentElement.dataset.action.includes("modal#show") :
        false;

      currentElement = currentElement.parentElement;
    }

    return isTrixFileInput || isShowButton;
  }
}
