
import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  select(event) {
    this.element.querySelectorAll("[aria-selected=true]").forEach(element => {
      element.setAttribute("aria-selected", false);
    });

    const target = event.currentTarget;
    const urlValue = target.getAttribute("data-url-value");

    if (urlValue) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      urlSearchParams.set("selection", urlValue);
      urlSearchParams.delete("tab");
    
      const newUrl = `${window.location.origin}${window.location.pathname}?${urlSearchParams.toString()}`;

      window.history.pushState({}, "", newUrl);
    }

    target.setAttribute("aria-selected", true);
  }
}
