//
// Tells the iOS app what badge counts to use for notifications via the JavaScript bridge.
// Not meant to be used directly, use app/helpers/ios/badges_helper.rb.
//
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    app: Number,
    tabs: Object
  }

  connect() {
    window.webkit?.messageHandlers?.nativeApp?.postMessage({
      name: "setBadgeCounts",
      data: {
        app: this.appValue,
        ...this.tabsValue
      }
    })
  }
}
