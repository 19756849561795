import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  remove(event) {
    const button = event.currentTarget as HTMLButtonElement;
    const correspondingClientInputId = button.getAttribute("data-corresponding-client-input-id");
    document.querySelectorAll(`#${correspondingClientInputId}`).forEach(element => element.remove());
    const correspondingClientRowId = button.getAttribute("data-corresponding-client-row-id");
    document.querySelectorAll(`#${correspondingClientRowId}`).forEach(element => element.remove());
    const correspondingClientComboboxInputId = button.getAttribute("data-corresponding-client-combobox-input-id");
    document.querySelectorAll(`#${correspondingClientComboboxInputId}`).forEach(element => element.remove());
  }
}
