/**
 * Module to help submit API requests to ios sign_up requests
 */

import Rails from "@rails/ujs";

export type CreateResult = {
  success: boolean;
  text?: string;
};

export async function create({firstName, lastName, email, providerName, providerId}): Promise<CreateResult> {
  const url = "/ios/sign_ups";

  const payload = {
    sign_up: {
      first_name: firstName,
      last_name: lastName,
      email: email,
      provider_name: providerName,
      provider_id: providerId
    }
  };

  const res = await fetch(url, {
    method: "POST",
    headers: {
      "Accept": "text/vnd.turbo-stream.html",
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken()
    },
    body: JSON.stringify(payload)
  });

  if (res.ok)
    return {success: true};
  else if (res.status == 422)
    return {success: false, text: await res.text()};
  else
    return {success: false};
}
