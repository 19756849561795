/**
 * Controls the client side behavior of the select plan screen part
 * of the sign up flow.
 */

import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkoutBreadcrumb", "nextButton", "finishButton", "form"];

  connect() {
    /**
     * Make sure the correct UI is shown
     * - Paid plan (checkout & next button)
     * - Free plan (no checkout and finish button)
     */

    this.handleChange();
  }

  handleChange() {
    const checkoutRequired = this.isCheckoutRequired();
    this.updateUi(checkoutRequired);
  }

  isCheckoutRequired() {
    const radios = Array.from(this.formTarget.elements["sign_up[plan]"]);
    const checkedRadio = radios.find(radio => radio.checked);
    return checkedRadio?.getAttribute("data-requires-checkout") == "true";
  }

  updateUi(checkoutRequired) {
    this.checkoutBreadcrumbTargets.forEach(breadCrumb => {
      breadCrumb.classList.toggle("hidden", !checkoutRequired);
    });

    this.nextButtonTargets.forEach(nextButton => {
      nextButton.classList.toggle("hidden", !checkoutRequired);      
    });

    this.finishButtonTargets.forEach(finishButton => {
      finishButton.classList.toggle("hidden", checkoutRequired);
    });
  }
}