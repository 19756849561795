/**
 * Client side behavior for kanban boards
 */

import {Controller} from "@hotwired/stimulus";
import Sortable from "sortablejs";
import {enter, leave} from "el-transition";
import * as BoardsStagesApi from "../lib/api/boards/stages";
import * as BoardsCardsApi from "../lib/api/boards/cards";
import Flash from "../lib/flash";
import * as Util from "../lib/util";
import Constants from "../lib/constants";

export default class extends Controller {
  static targets = ["stageColumns", "stage", "column", "event", "newCardWrapper", "cardHover"];

  static values = {
    hexId: String
  };
  
  stageColumnsTargetConnected(element) {
    new Sortable(element, {
      animation: 150,
      draggable: ".sortablejs-draggable",
      filter: ".sortablejs-ignore",
      handle: ".sortablejs-handle",
      ghostClass: "opacity-30",
      preventOnFilter: false,
      onEnd: this.handleStageDrop.bind(this)
    });
  }

  columnTargetConnected(element) {
    new Sortable(element, {
      group: "columns",
      animation: 150,
      draggable: ".sortablejs-draggable",
      ghostClass: "opacity-30",
      filter: ".sortablejs-ignore",
      dataIdAttr: "data-card-hex-id",
      preventOnFilter: false,
      onEnd: this.handleCardDrop.bind(this),
      onStart: this.handleCardDragStart.bind(this)
    });
  }

  eventTargetConnected(element) {
    const type = element.getAttribute("data-type");

    switch (type) {
    case "cardMove":
      this.handleCardMoveEvent(element);
      break;
    case "closeSlideOver":
      this.handleCloseSlideOverEvent();
      break;
    case "reloadLoadedDetailFrames":
      this.handleReloadLoadedDetailFramesEvent(element);
      break;
    }

    const streamMessage = element.innerHTML;

    if (streamMessage)
      Turbo.renderStreamMessage(streamMessage);
  }

  cardHoverTargetConnected(element) {
    element.addEventListener("mouseenter", () => {
      if (!this.cardIsDragging()) {
        this.applyCardHoverState(element);
      }
    });

    element.addEventListener("mouseleave", () => {
      this.removeCardHoverState(element);
    });
  }

  /**
   * actions
   */

  insertNewStageForm(event) {
    const button = event.currentTarget;
    const stageWrapperSelector = button.getAttribute("data-stage-selector");
    const placement = button.getAttribute("data-placement");
    const stageWrapper = this.element.querySelector(stageWrapperSelector);
    const stageIndex = Array.from(this.stageColumnsTarget.querySelectorAll("li[data-board-target=stage]")).indexOf(stageWrapper);
    const order = placement == "before" ? stageIndex + 1 : stageIndex + 2;

    const newElement = document.createElement("turbo-frame");
    newElement.className = "w-full md:w-72 min-h-16 mx-0 md:mx-4";
    newElement.id = `new_stage_order_${order}`;
    newElement.setAttribute("src", `/boards/${this.hexIdValue}/stages/new?order=${order}`);
    newElement.setAttribute("loading", "lazy");
    newElement.innerHTML = Constants.LOADING_HTML;

    if (placement == "before") {
      stageWrapper.before(newElement);
    } else {
      stageWrapper.after(newElement);
    }
  }

  removeNewStageForm(event) {
    const button = event.currentTarget;
    const removeSelector = button.getAttribute("data-remove-selector");
    document.querySelector(removeSelector)?.remove();
  }

  /**
   * helpers
   */

  async handleStageDrop(event) {
    const newIndex = event.newDraggableIndex;
    const stageHexId = event.item.dataset.stageHexId;

    try {
      const res = await BoardsStagesApi.sendDropUpdate({
        boardHexId: this.hexIdValue,
        stageHexId,
        order: newIndex + 1
      });
  
      Turbo.renderStreamMessage(res);

    } catch (error) {
      console.error(`Error in handleStageDrop ${error.message}`);
      Flash.addFlash("Sorry, we weren't able to move that card. Please refresh the page.", "error");
    }
  }

  async handleCardDrop(event) {
    const cardHexId = event.item.dataset.cardHexId;
    const newIndex = event.newDraggableIndex;
    const oldStageHexId = event.from.dataset.stageHexId;
    const newStageHexId = event.to.dataset.stageHexId;

    this.applyCardHoverState(this.currentlyDraggingCard);
    this.currentlyDraggingCard = null;

    this.showAllNewCardSections();

    /**
     * If the dropped card is the first card in the stage, make sure that it
     * is not below the new card wrapper.
     */
    const newStageElement = event.to;
    const newStageSortable = Sortable.get(newStageElement).toArray(); 
  
    if (newStageSortable.length == 1) {
      // Note that length includes the new element that was dropped
      const drugElement = event.item;
      drugElement.remove();
      newStageElement.prepend(drugElement);
    }

    let apiParams = {
      boardHexId: this.hexIdValue,
      cardHexId,
      order: newIndex + 1, // orders start at 1 on server,
      context: Util.getCurrentContext()
    };

    if (oldStageHexId != newStageHexId) {
      /**
       * Card also changed stages, need to update with new hexId
       */

      apiParams.stageHexId = newStageHexId;
    }

    try {
      const res = await BoardsCardsApi.sendDropUpdate(apiParams);
      Turbo.renderStreamMessage(res);

    } catch (error) {
      console.error(`Error in handleStageDrop ${error.message}`);
      Flash.addFlash("Sorry, we weren't able to move that card. Please refresh the page.", "error");
    }
  }

  handleCardDragStart(event) {
    this.cardHoverTargets.forEach(element => this.removeCardHoverState(element));
    this.currentlyDraggingCard = event.item.querySelector("[data-board-target=cardHover]");

    this.hideAllNewCardSections();
  }

  hideAllNewCardSections() {
    this.newCardWrapperTargets.forEach(wrapper => {
      leave(wrapper);
    });
  }

  showAllNewCardSections() {
    this.newCardWrapperTargets.forEach(wrapper => {
      enter(wrapper);
    });
  }

  handleCloseSlideOverEvent() {
    
  }

  handleCardMoveEvent(element) {
    const newStageHexId = element.getAttribute("data-new-stage-hex-id");
    const cardHexId = element.getAttribute("data-card-hex-id");
    const order = parseInt(element.getAttribute("data-order"));
    const previousOrder = parseInt(element.getAttribute("data-previous-order"));
    const previousStageHexId = element.getAttribute("data-previous-stage-hex-id");

    this.moveColumnCard({cardHexId, newStageHexId, order, previousOrder, previousStageHexId});
  }

  // Replace card details slideover sections for cards in given stage
  handleReloadLoadedDetailFramesEvent(element) {
    const stageId = element.getAttribute("data-stage");
    const frames = document.querySelectorAll(`turbo-frame[id^='boards_cards_types_contact_'][data-stage='${stageId}]'`);

    frames.forEach(frame => {
      frame.reload();
    });
  }

  moveColumnCard({cardHexId, newStageHexId, order, previousOrder, previousStageHexId}) {
    const previousStageElement = this
      .element
      .querySelector(`ul[data-stage-hex-id='${previousStageHexId}'][data-orientation=column]`);

    if (previousStageElement == null)
      throw new Error(`Unable to move column card ${cardHexId}: The previous stage ${previousStageHexId} ul element does not exist with data-orientation=column`);

    const cardElement = previousStageElement.querySelector(`li[data-card-hex-id='${cardHexId}']`);

    const newStageListElement = this
      .element
      .querySelector(`ul[data-orientation=column][data-stage-hex-id='${newStageHexId}']`);

    const listSortable = Sortable.get(newStageListElement);
    const sortables = listSortable.toArray();

    let insertTargetHexId = null;
    let insertTarget = null;

    if (order > previousOrder && newStageHexId == previousStageHexId) {
      insertTargetHexId = sortables.at(order);
    } else {
      insertTargetHexId = sortables.at(order - 1);
    }

    /**
     * We don't need to move the card if this is the case since if would be
     * inserted before itself, ie in the same position.
     */
    if (insertTargetHexId == cardHexId)
      return;

    const selector = `li[data-card-hex-id='${insertTargetHexId}']`;
    insertTarget = newStageListElement.querySelector(selector);

    if (insertTarget == null) {
      const insertionPointSelector = `#stage_${newStageHexId}_new_card_insertion_point`;
      insertTarget = newStageListElement.querySelector(insertionPointSelector);
    }

    cardElement.remove();
    insertTarget.before(cardElement);
  }

  applyCardHoverState(element) {
    element.classList.toggle("bg-concert-600", true);
  }

  removeCardHoverState(element) {
    element.classList.toggle("bg-concert-600", false);
  }

  cardIsDragging() {
    return this.currentlyDraggingCard != null;
  }
}
