/**
 * Client side behavior for rich text areas, mainly autosave.
 *
 * Attributes:
 * data-attribute-human-name: The name of the attribute we are editing to include in the
 * toolbar's header. (Optional)
 *
 * data-data-forms--rich-text-area-protect-file-types-value: A comma separated list of MIME
 * types to limit attachment upload by.
 *
 * data-data-forms--rich-text-area-protect-size-mb-value: A number (in mb) to limit
 * attachment uploads by. (Eg 5 = max 5mb attachment size)
 */

import {Controller} from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import {debounce} from "lodash";
import {getStickyNavHeight, timeAgoInWords} from "../../lib/util";
import Files from "../../lib/files";
import Flash from "../../lib/flash";

export default class extends Controller {
  static targets = ["editor", "message", "loading", "submit"];

  static values = {
    protectSizeMb: Number,
    protectFileTypes: String,
    protectFileTypesHumanized: String,
    refocusAfterSubmit: Boolean
  };

  /**
   * lifecycle
   */

  connect() {
    this.autosave = debounce(this.doAutosave, 300);
    this.errorStatePreviewReplacementUrl = "https://via.placeholder.com/400x225.png?text=Error%20loading%20preview";

    /**
     * Prevents form submission until the attachment is completed uploading
     */
    this.editorTarget.addEventListener("trix-attachment-add", event => {
      let intervalId;
      const containingFormElement = this.getContainingFormElement();

      if (containingFormElement) {
        this.disableFormSubmit(containingFormElement);

        intervalId = setInterval(() => {
          const pending = event.attachment.isPending();

          if (!pending) {
            clearInterval(intervalId);
            this.enableFormSubmit(containingFormElement);
          }
        }, 10);
      }
    });
  }

  editorTargetConnected() {
    this.setToolbarStickyPosition();
    this.replaceHeicImagePreviews();

    window.addEventListener("resize", () => {
      this.setToolbarStickyPosition();
    });
  }

  messageTargetConnected() {

    /**
     * Check every 1s if we should updated the last saved at message
     */
    this.startMessageInterval();
  }

  disconnect() {
    this.clearMessageInterval();
  }

  /**
   * actions
   */
  
  doAutosave() {
    if (!this.autosaveUrl()) {
      console.error("attempted to autosave a rich text area with no data-autosave-url.");
      return;
    }

    fetch(this.autosaveUrl(), {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken()
      },
      body: JSON.stringify(this.autosavePayload())

    }).then(res => {
      this.hideLoading();

      if (res.ok) {
        this.lastSaved = Date.now();
        this.updateLastSavedMessage();

      } else if (res.status == 422) {
        // validation errors
        return res.json();

      } else {
        this.setMessageHTML(
          "We couldn't autosave your changes. You can still submit the form.",
          {
            error: true,
            classes: "text-sm text-crimson-400"
          }
        );

      }

    }).then(json => {
      let html = "";

      if (json) {
        json.errors.forEach(error => {
          html += `<p class="text-sm text-crimson-400">${error}</p>`;
        });
  
        this.setMessageHTML(html, {error: true}); 
      }

    }).catch(error => {
      console.error(error);

      this.setMessageHTML(
        "We couldn't autosave your changes. You can still submit the form.",
        {
          error: true,
          classes: "text-sm text-crimson-400"
        }
      );
    });

    this.showLoading();
  }

  verifyAttachment(event) {
    const file = event.file;

    const {success, message} = Files.verifyAttachment(file, {
      fileTypes: this.protectFileTypesValue,
      fileTypesHumanized: this.protectFileTypesHumanizedValue,
      maxSizeMb: this.protectSizeMbValue
    });

    if (!success) {
      Flash.addFlash(message, "error");
      event.preventDefault();
    }
  }

  replaceHeicImagePreviews() {
    const imageContainers = document.querySelectorAll("trix-editor .attachment--heic, .attachment--heif");

    if (imageContainers) {
      Array.from(imageContainers).forEach(container => {
        let trixInfo = JSON.parse(container.getAttribute("data-trix-attachment"));
        let sgid = trixInfo.sgid;
        let image = container.querySelector("img");

        // Get the replacement blob URL from the server
        // based on the attachment sgid
        fetch(`/files/blob_urls?sgid=${sgid}`, {
          method: "GET",
          headers: {
            "X-CSRF-Token": Rails.csrfToken()
          }

        })
          .then(res => {
            if (res.ok) {
              res.json().then(data => {
                if (data.cloudinary_url) {
                  image.src = data.cloudinary_url;
                }
              });
            } else {
              image.src = this.errorStatePreviewReplacementUrl;
            }
          }).catch(error => {
            image.src = this.errorStatePreviewReplacementUrl;
            console.error(error);
          });
      });
    }
  }

  /**
   * helpers
   */

  autosaveUrl() {
    return this.editorTarget.getAttribute("data-autosave-url");
  }

  autosavePayload() {
    const payload = {};
    const object = this.editorTarget.getAttribute("data-autosave-object");
    const attribute = this.editorTarget.getAttribute("data-autosave-attribute");

    if (object) {
      payload[object] = {};
      payload[object][attribute] = this.editorTarget.value;
    } else {
      payload[attribute] = this.editorTarget.value;
    }

    return payload;
  }

  setMessageHTML(html, options = {}) {
    this.messageTarget.innerHTML = html;
    this.messageTarget.className = options.classes || "";

    if (options.error) {
      this.messageTarget.setAttribute("data-error", true);
      this.clearMessageInterval();

    } else {
      this.messageTarget.setAttribute("data-error", false);

      if (!this.messageInterval)
        this.startMessageInterval();
    }
  }

  /**
   * Sets the top position of the toolbar so that it will stick in the appropriate
   * place. By default this is the height of the sticky nav, but can be overridden
   * with a data attribute (data-toolbar-sticky-offset) in pixels.
   */
  setToolbarStickyPosition() {
    if (this.isSmallToolbar())
      return;

    const toolbar = this.element.querySelector("trix-toolbar");
    const overrideValue = this.element.getAttribute("data-toolbar-sticky-offset");
    let offset;

    if (overrideValue)
      offset = overrideValue.toString() + "px";
    else
      offset = getStickyNavHeight().toString() + "px";

    toolbar.style.top = offset;
  }

  updateLastSavedMessage() {
    if (this.lastSaved) {
      const lastMessage = this.messageTarget.innerText.trim();
      const newMessage = "Last saved "  + timeAgoInWords(this.lastSaved).toString();

      if (lastMessage != newMessage) {
        this.setMessageHTML(newMessage, {classes: "text-sm text-concert-400"});
      }
    }
  }

  clearMessageInterval() {
    if (this.messageInterval) {
      clearInterval(this.messageInterval);
    }
  }

  startMessageInterval() {
    if (this.messageInterval)
      clearInterval(this.messageInterval);

    this.messageInterval = setInterval(() => {
      this.updateLastSavedMessage();
    }, 1000);
  }

  showLoading() {
    this.loadingTarget.classList.toggle("hidden", false);

    /**
     * Because of size constraints, we can't show loading and the message
     * at the same time
     */
    this.messageTarget.classList.toggle("hidden", true);
  }

  hideLoading() {
    this.loadingTarget.classList.toggle("hidden", true);

    /**
     * Because of size constraints, we can't show loading and the message
     * at the same time
     */
    this.messageTarget.classList.toggle("hidden", false);
  }

  isSmallToolbar() {
    return this.element.getAttribute("data-small-toolbar") == "true";
  }

  getInputElement() {
    const inputId = this.editorTarget.getAttribute("input");
    return this.element.querySelector(`#${inputId}`);
  }

  getContainingFormElement(parent = null) {
    if (!parent)
      parent = this.element.parentElement;

    if (parent.tagName == "FORM") {
      return parent;
    } else if (parent.parentElement){
      return this.getContainingFormElement(parent.parentElement);
    }

    return null;
  }

  disableFormSubmit(formElement) {
    const submitButtons = formElement.querySelectorAll("input[type=submit]");

    submitButtons.forEach(submitButton =>  {
      submitButton.setAttribute("disabled", "");
      submitButton.classList.toggle("opacity-20", true);
      submitButton.classList.toggle("cursor-not-allowed", true);
      submitButton.classList.toggle("hover:cursor-not-allowed", true);
    });
  }

  enableFormSubmit(formElement) {
    const submitButtons = formElement.querySelectorAll("input[type=submit]");

    submitButtons.forEach(submitButton =>  {
      submitButton.removeAttribute("disabled");
      submitButton.classList.toggle("opacity-20", false);
      submitButton.classList.toggle("cursor-not-allowed", false);
      submitButton.classList.toggle("hover:cursor-not-allowed", false);
    });
  }

  submitForm(e) {
    if (!e.shiftKey) {
      e.preventDefault();
      this.submitTarget.click();

      if (this.refocusAfterSubmitValue) {
        this.refocus();
      }
    }
  }

  refocus() {
    this.editorTarget.focus();
  }
}
