/**
 * Client side behavior for form based comboboxes
 */

import {Controller} from "@hotwired/stimulus";
import {debounce} from "lodash";
import KeyNavigatedList from "../../lib/key_navigated_list";

export default class extends Controller {
  static targets = ["input", "frame", "list", "value", "text", "panel", "scrollArea"];

  static values = {
    searchPath: String,

    /**
     * Determines what happens when a user clicks or presses enter on a result
     * - link: Clicks the first link found inside the result
     * - form: Fills out the hidden form field
     * - submit: Fills out the hidden form field & submits the form
     */
    onChooseBehavior: String,

    // A selector for the form to submit when 'submit' onChooseBehavior is utilized
    onChooseSubmitFormSelector: String
  };

  /**
   * lifecycle
   */

  connect() {
    this.list = new KeyNavigatedList(
      this.panelTarget,
      this.panelTarget, {
        onSelect: this.selectResult.bind(this),
        onUnselect: this.unselectResult.bind(this),
        onChoose: this.chooseResult.bind(this)
      }
    );

    this.submit = debounce(this.performSubmit.bind(this), 300);
  }

  listTargetConnected() {
    const sectionHeaderObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        mutation.removedNodes.forEach(removedNode => {
          const nodeSection = removedNode.getAttribute("data-section");
          const nodeIsOption = removedNode.nodeName === "LI";

          if (nodeSection && nodeIsOption) {
            const remainingNodesInSection = this
              .listTarget
              .querySelectorAll(`li[data-section="${nodeSection}"]`)
              .length;

            if (remainingNodesInSection <= 0) {
              const sectionHeader = this
                .listTarget
                .querySelector(`h5[data-section="${nodeSection}"]`);

              // NOTE not all comboboxes have section headers
              if (sectionHeader)
                sectionHeader.remove();
            }
          }
        });
      });
    });

    sectionHeaderObserver.observe(
      this.listTarget,
      {subtree: false, childList: true}
    );
  }

  /**
   * actions
   */

  start() {
    this.panelTarget.classList.toggle("hidden", false);
  }

  stop() {
    this.panelTarget.classList.toggle("hidden", true);
  }

  handleClick(event) {
    event.preventDefault();

    const item = event.currentTarget;
    this.chooseResult(item, {selectNext: false});
  }

  handleKeyDown(event) {
    this.list.handleKeyDown(event);
  }

  handleDocumentClick(event) {
    const path = event.composedPath();
    const target = event.target;
    const targetIsSubmit = target.tagName == "INPUT";
    const includesListTarget = this.hasListTarget && path.includes(this.listTarget);

    if (!includesListTarget && !targetIsSubmit) {
      this.stop();
    }
  }

  handleDocumentKeyDown(event) {
    if (event.code == "Escape") {
      this.stop();
      this.inputTarget.value = "";
      this.inputTarget.blur();
      this.frameTarget.src = this.searchPathValue;
      this.frameTarget.reload();
      event.preventDefault();
    }
  }

  /**
   * helpers
   */

  performSubmit() {
    const query = this.inputTarget.value;

    if (query.length >= 3 || query.length == 0) {
      const encoded = encodeURIComponent(query);
      let url;

      if (this.searchPathValue.includes("?"))
        url = this.searchPathValue + `&query=${encoded}`;
      else
        url = this.searchPathValue + `?query=${encoded}`;

      this.frameTarget.setAttribute("src", url);
      this.frameTarget.reload();
    }
  }

  selectResult(element) {
    element.classList.toggle("bg-concert-700/50", true);
  }

  unselectResult(element) {
    element.classList.toggle("bg-concert-700/50", false);
  }

  chooseResult(element, {selectNext} = {selectNext: true}) {
    if (this.onChooseBehaviorValue == "link") {
      /**
       * Clicks the first link it finds in the result
       */

      const link = element.querySelector("a");

      if (link)
        link.click();

      if (selectNext) {
        const next = this.list.getNextSelectableSibling(element);
  
        if (next) {
          this.list.select(next);
        } else {
          const prev = this.list.getPreviousSelectableSibling(element);
  
          if (prev)
            this.list.select(prev);
        }
      }

    } else if (this.onChooseBehaviorValue == "form") {
      /**
       * Updates a hidden form field with the value from the result
       */

      const value = element.getAttribute("data-value");
      const text = element.getAttribute("data-text");

      this.valueTarget.value = value;

      if (this.hasTextTarget)
        this.textTarget.innerText = text;

      this.stop();

    } else if (this.onChooseBehaviorValue == "submit") {
      /**
       * Updates a hidden form field with the value from the result then
       * submits a form with the provided selector.
       */

      const value = element.getAttribute("data-value");
      const selector = this.onChooseSubmitFormSelectorValue;
      const form = document.querySelector(selector);

      if (!form)
        throw new Error(`Expected to find a form matching ${selector}. Unable to auto submit form from combobox.`);

      this.valueTarget.value = value;

      form.requestSubmit();
      this.stop();

    }
  }
}
