/**
 * Client side behavior for the invitation modal
 */

import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["frame"];

  refreshInviteLink(event) {
    const locationHexId = event.currentTarget.value;
    let newFrameUrl;

    if (locationHexId != "none") {
      newFrameUrl = `/teams/3372ff94/invitation/link?location_hex_id=${locationHexId}`;
    } else {
      newFrameUrl = "/teams/3372ff94/invitation/link";
    }

    this.frameTarget.setAttribute("src", newFrameUrl);
  }
}
