/**
 * Module that helps to submit API requests for lesson sources
 */

import Rails from "@rails/ujs";
import * as UpChunk from "@mux/upchunk";

export async function create({status = "initial", providerName, lessonHexId}) {
  const url = `/lessons/${lessonHexId}/sources`;

  const sourceCreateData = {
    source: {
      status: status,
      provider_name: providerName
    }
  };

  const res = await fetch(url, {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken()
    },
    body: JSON.stringify(sourceCreateData)
  });

  return await res.json();
}

export async function uploadToMux({
  file,
  lessonHexId,
  sourceHexId,
  handleProgress,
  handleSuccess,
  handleFailure
}) {
  /**
   * Get a direct upload URL from the server
   */

  let directUploadUrl = null;

  try {
    const createDirectUploadUrl = `/lessons/${lessonHexId}/sources/${sourceHexId}/mux_direct_uploads/new`;
    const createDirectUploadResponse = await fetch(createDirectUploadUrl);

    if (createDirectUploadResponse.ok) {
      const directUploadData = await createDirectUploadResponse.json();
      directUploadUrl = directUploadData.url;

    } else {
      throw new Error("Unable to get mux direct upload url. Server did not respond 200.");
    }

  } catch (error) {
    console.error(`An error occurred while getting mux direct upload url. error: ${error.message}`);
    throw error;
  }

  /**
   * Upload the file to Mux
   */

  const upload = UpChunk.createUpload({
    endpoint: directUploadUrl,
    file: file,
    chunkSize: 5120
  });
  
  upload.on("error", error => {
    console.error(`encountered an error while uploading file: ${error.message}`);
    handleFailure();
  });
  
  upload.on("progress", progress => {
    handleProgress({
      filename: file.name,
      percent: progress.detail
    });
  });

  upload.on("success", () => {
    handleSuccess({byteSize: file.size});
  });

  return upload;
}

export async function uploadingUpdate({filename, lessonHexId, sourceHexId}) {
  const url = `/lessons/${lessonHexId}/sources/${sourceHexId}`;

  const body = {
    source: {
      status: "uploading",
      filename
    }
  };

  const res = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Accept": "text/vnd.turbo-stream.html",
      "X-CSRF-Token": Rails.csrfToken()
    },
    body: JSON.stringify(body)
  });

  if (res.ok) {
    /**
     * NOTE If successful, the response will include a turbo_stream payload that should
     * be rendered by the caller
     */

    return res.text();
  }

  throw new Error("Unable to complete uploadingUpdate request. Server did not respond 200.");
}

export async function uploadSuccessUpdate({byteSize, sourceHexId, lessonHexId}) {
  const url = `/lessons/${lessonHexId}/sources/${sourceHexId}`;

  const body = {
    source: {
      byte_size: byteSize
    }
  };

  const res = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Accept": "text/vnd.turbo-stream.html",
      "X-CSRF-Token": Rails.csrfToken()
    },
    body: JSON.stringify(body)
  });

  if (res.ok) {
    /**
     * NOTE If successful, the response will include a turbo_stream payload that should
     * be rendered by the caller
     */

    return res.text();
  }

  throw new Error("Unable to complete uploadSuccessUpdate API request. Server did not return 200.");
}

export async function cancelledUpdate({lessonHexId, sourceHexId}) {
  const url = `/lessons/${lessonHexId}/sources/${sourceHexId}`;

  const body = {
    source: {
      status: "cancelled"
    }
  };

  const res = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Accept": "text/vnd.turbo-stream.html",
      "X-CSRF-Token": Rails.csrfToken()
    },
    body: JSON.stringify(body)
  });

  if (res.ok) {
    /**
     * NOTE If successful, the response will include a turbo_stream payload that should
     * be rendered by the caller
     */

    return res.text();
  }

  throw new Error("Unable to complete cancelledUpdate API request. Server did not return 200.");
}

export async function failureUpdate({lessonHexId, sourceHexId}) {
  const url = `/lessons/${lessonHexId}/sources/${sourceHexId}`;

  const body = {
    source: {
      status: "failed"
    }
  };

  const res = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Accept": "text/vnd.turbo-stream.html",
      "X-CSRF-Token": Rails.csrfToken()
    },
    body: JSON.stringify(body)
  });

  if (res.ok) {
    /**
     * NOTE If successful, the response will include a turbo_stream payload that should
     * be rendered by the caller
     */

    return res.text();
  }

  throw new Error("Unable to complete cancelledUpdate API request. Server did not return 200.");
}
