/**
 * Client-side behavior for custom lesson source uploads
 */

import {Controller} from "@hotwired/stimulus";
import * as LessonSourcesApi from "../../lib/api/lessons/sources";
import * as TeamStorageChecksApi from "../../lib/api/teams/storage_checks";
import Flash from "../../lib/flash";

export default class extends Controller {
  static targets = ["file", "next", "another", "progress", "progressContainer", "successContainer", "failureContainer", "dragArea"];

  /**
   * actions
   */

  async upload() {

    const file = this.getFile();

    /**
     * Ensure the team has space to upload this video file
     */

    const teamHasStorageSpace = await TeamStorageChecksApi.canUploadFile({byteSize: file.size});

    if (!teamHasStorageSpace) {
      this.displayError("Sorry, your team does not have enough storage left for that file. Please check your team's storage limit.");
      this.fileTarget.value = null;
      return;
    }


    /**
     * Create the source in the database if it does not yet exist
     */

    if (this.getSourceIsNew()) {
      try {
        const data = await LessonSourcesApi.create({
          status: "initial",
          providerName: "mux",
          lessonHexId: this.getLessonHexId()
        });

        this.setLessonSourceHexId(data.source.hex_id);

      } catch (error) {
        this.displayError("Sorry, we weren't able add that video to MxU");
        console.error(error.message);

        return;
      }
    }

    try {
      // Callback that is called when progress is reported by the XHR request.
      const handleProgress = ({percent, filename}) => {
        if (!this.sentUploadingUpdate)
          this.sendUploadingUpdate(filename);

        this.updateProgress(percent);
      };

      // Callback called when the upload has successfully completed
      const handleSuccess = ({byteSize}) => {
        this.handleUploadSuccess({byteSize});
      };

      /**
       * Callback that is called in the event of a failed request to Cloudinary. This could be
       * a non-200 response or lack of a public id in the final response payload.
       */
      const handleFailure = () => {
        this.displayError("Sorry, we weren't able to upload that video");

        LessonSourcesApi.failureUpdate({
          lessonHexId: this.getLessonHexId(),
          sourceHexId: this.getLessonSourceHexId()
        });
      };

      this.currentUpload = await LessonSourcesApi.uploadToMux({
        file,
        lessonHexId: this.getLessonHexId(),
        sourceHexId: this.getLessonSourceHexId(),
        handleProgress,
        handleSuccess,
        handleFailure
      });

    } catch (error) {
      this.displayError("Sorry, something went wrong while uploading that video");

      LessonSourcesApi.failureUpdate({
        lessonHexId: this.getLessonHexId(),
        sourceHexId: this.getLessonSourceHexId()
      });

      console.error(`uploadToMux request failed error ${error.message}`);
    }
  }

  async cancelUpload() {
    if (this.currentUpload)
      this.currentUpload.abort();

    this.showFailure("Upload cancelled. Select a file to try again.");

    const response = await LessonSourcesApi.cancelledUpdate({
      lessonHexId: this.getLessonHexId(),
      sourceHexId: this.getLessonSourceHexId()
    });

    // eslint-disable-next-line no-undef
    Turbo.renderStreamMessage(response);
  }

  preventNext(event) {
    event.preventDefault();
  }

  /**
   * helpers
   */

  async handleUploadSuccess({byteSize}) {
    try {
      this.enableNextButton();
      this.enableAnotherButton();
      this.hideProgress();
      this.showSuccess();

      // update the source with the byte size now that it is finished uploading
      await LessonSourcesApi.uploadSuccessUpdate({
        byteSize: byteSize,
        lessonHexId: this.getLessonHexId(),
        sourceHexId: this.getLessonSourceHexId()
      });

    } catch (error) {
      this.displayError("Sorry, we were unable to add that video to MxU");

      LessonSourcesApi.failureUpdate({
        lessonHexId: this.getLessonHexId(),
        sourceHexId: this.getLessonSourceHexId()
      });

      console.error(error.message);
    }
  }

  async sendUploadingUpdate(filename) {
    this.sentUploadingUpdate = true;

    try {

      const response = await LessonSourcesApi.uploadingUpdate({
        filename,
        lessonHexId: this.getLessonHexId(),
        sourceHexId: this.getLessonSourceHexId()
      });

      // eslint-disable-next-line no-undef
      Turbo.renderStreamMessage(response);

    } catch (error) {
      /**
       * NOTE A flash message is not require here because the whole process could
       * still complete successfully even if this step fails. We also don't want
       * to send a failure update to the server.
       */

      console.error(error.message);
    }
  }

  async updateProgress(percent) {
    this.showProgress();
    this.progressTarget.style.width = percent.toString() + "%";
  }

  setLessonSourceHexId(hexId) {
    this.element.setAttribute("data-lesson-source-hex-id", hexId.toString());
  }

  enableNextButton() {
    if (!this.hasNextTarget)
      return;

    const currentDataAction = this.nextTarget.getAttribute("data-action");
    const newDataAction = currentDataAction.replace("lessons--source-upload#preventNext", " ");
    this.nextTarget.setAttribute("data-action", newDataAction);

    this.nextTarget.classList.toggle("hover:cursor-not-allowed", false);
    this.nextTarget.classList.toggle("opacity-20", false);
    this.nextTarget.classList.toggle("pointer-events-none", false);
    this.nextTarget.classList.toggle("hover:cursor-pointer", true);
    this.nextTarget.setAttribute("title", null);
  }

  enableAnotherButton() {
    if (this.hasAnotherTarget) {
      const currentDataAction = this.anotherTarget.getAttribute("data-action");
      const newDataAction = currentDataAction.replace("lessons--source-upload#preventNext", " ");
      this.anotherTarget.setAttribute("data-action", newDataAction);

      this.anotherTarget.classList.toggle("hover:cursor-not-allowed", false);
      this.anotherTarget.classList.toggle("opacity-20", false);
      this.anotherTarget.classList.toggle("pointer-events-none", false);
      this.anotherTarget.classList.toggle("hover:cursor-pointer", true);
    }
  }

  displayError(message) {

    Flash.addFlash(
      message,
      "error"
    );

    this.showFailure();
  }

  showProgress() {
    this.hideSuccess();
    this.hideFailure();
    this.dragAreaTarget.classList.remove("dropped");
    this.progressContainerTarget.classList.toggle("hidden", false);
  }

  hideProgress() {
    this.progressContainerTarget.classList.toggle("hidden", true);
  }

  showSuccess() {
    this.hideProgress();
    this.hideFailure();
    this.successContainerTarget.classList.toggle("hidden", false);
  }

  hideSuccess() {
    this.successContainerTarget.classList.toggle("hidden", true);
  }

  showFailure(message = "Failed") {
    this.hideSuccess();
    this.hideProgress();
    this.dragAreaTarget.classList.remove("dropped");

    this
      .failureContainerTarget
      .classList
      .toggle("hidden", false);

    this
      .failureContainerTarget
      .querySelector("[data-container-target=text]")
      .innerText = message;
  }

  hideFailure() {
    this.failureContainerTarget.classList.toggle("hidden", true);
  }

  getWebhookHost() {
    return this.element.getAttribute("data-webhook-host");
  }

  getEagerTransformation() {
    return this.element.getAttribute("data-eager-transformation");
  }

  getLessonHexId() {
    return this.element.getAttribute("data-lesson-hex-id");
  }

  getLessonSourceHexId() {
    return this.element.getAttribute("data-lesson-source-hex-id");
  }

  getSourceIsNew() {
    return this.element.getAttribute("data-is-new") == "true";
  }

  getFile() {
    return this.fileTarget.files[0];
  }

  dragOver() {
    this.dragAreaTarget.classList.add("drag-over");
  }

  dragLeave() {
    this.dragAreaTarget.classList.remove("drag-over");
  }

  drop() {
    this.dragAreaTarget.classList.remove("drag-over");
    this.dragAreaTarget.classList.add("dropped");
  }

}
