import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["progress"];
  static outlets = ["player", "toggle"];

  static values = {
    nextLessonPath: String,
    cancelled: Boolean,
    forceCompleted: Boolean
  };

  connect() {
    if (!this.getAutoPlayEnabled())
      return;

    this.playbackStarted = false;

    this
      .getPlayerAdapter()
      .onTimeupdate(this.handleTimeUpdate.bind(this));
  }

  disconnect() {
    if (this.interval)
      clearInterval(this.interval);
  }

  /**
   * helpers
   */

  handleTimeUpdate(seconds) {
    this.playbackStarted = true;

    if (this.playerOutlet.durationValue - seconds <= 20 && !this.cancelledValue && this.getAutoPlayEnabled())
      this.show(this.playerOutlet.durationValue - seconds);
    else
      this.hide();
  }

  /**
   * helpers
   */

  show(showDurationInSeconds) {
    const shouldShow = this.element &&
      this.element.classList.contains("hidden") &&
      this.playbackStarted;

    if (shouldShow) {
      this.element.classList.toggle("hidden", false);

      const startTime = new Date().getTime();

      this.interval = setInterval(() => {
        const now = new Date().getTime();
        const ratio = (now - startTime) / (showDurationInSeconds * 1000);
        const percent = ratio * 100;

        this.progressTarget.style.width = `${percent}%`;

        if (percent > 100) {
          this.navigateToNextLesson();
        }
      }, 10);
    }
  }

  hide() {
    this.element.classList.toggle("hidden", true);
    this.progressTarget.style.width = "0%";
    clearInterval(this.interval);
  }

  cancel() {
    this.cancelledValue = true;
    this.hide();
  }

  getAutoPlayEnabled() {
    if (this.hasToggleOutlet) {
      return this.toggleOutlet.getEnabled();
    }

    return false;
  }

  getPlayerAdapter() {
    if (this.hasPlayerOutlet)
      return this.playerOutlet.getPlayerAdapter();

    throw new Error("Cannot get the player adapter, auto play banner controller has no player outlet.");
  }


  sendForceCompleteUpdate() {
    if (!this.forceCompletedValue) {
      /**
       * send a progress marker update to force this lesson to be complete
       * when auto play is completed
       */
      this
        .playerOutlet
        .behavior
        .updateProgressMarker(this.playerOutlet.durationValue, "forceComplete");

      this.forceCompletedValue = true;
    }
  }

  navigateToNextLesson() {
    const path = this.nextLessonPathValue;

    this.disconnect();
    this.sendForceCompleteUpdate();

    Turbo.visit(path);
  }
}
