import { Controller } from "@hotwired/stimulus"
import {enter, leave} from "el-transition";

//
// Animate basic showing/hiding of contents. Use show/hide button
// targets for things you only want to appear in certain states of the
// content, like "See 5 more users" text alongside a link that actually
// calls toggle() as its data-action (which would be the actual show
// button).
//
// Connects to data-controller="appearance"
//
export default class extends Controller {
  static targets = ["showButton", "hideButton", "hideable"];

  static values = {
    closed: Boolean,
    localName: String
  }

  connect() {
    this.hideableTargets.forEach(hideable => {
      hideable.classList.toggle("hidden", this.isClosed())
    })

    this.isClosed() ? this.doHiddenButtonState() : this.doRevealedButtonState()
  }

  toggle() {
    // currently closed; action is opening / revealing
    if (this.isClosed()) {
      // not promisified to speed things up
      this.hideableTargets.forEach(hideable => { enter(hideable) })
      this.doRevealedButtonState()

    // currently open; action is closing
    } else {
      let hidePromises = []

      this.hideableTargets.forEach(hideable => {
        hidePromises.push(leave(hideable))
      })

      Promise.all(hidePromises).then(() => {
        this.doHiddenButtonState()
      })
    }

    this.closedValue = !this.isClosed();
    localStorage.setItem(`${this.localNameValue}`, this.closedValue);
  }

  isClosed() {
    if (this.hasLocalNameValue) {
      return this.localClosedValue()
    } else {
      return this.closedValue
    }
  }

  doHiddenButtonState() {
    if (this.hasShowButtonTarget) {
      this.showButtonTarget.classList.remove("hidden")
    }

    if (this.hasHideButtonTarget) {
      this.hideButtonTarget.classList.add("hidden")
    }
  }

  doRevealedButtonState() {
    if (this.hasShowButtonTarget) {
      this.showButtonTarget.classList.add("hidden")
    }

    if (this.hasHideButtonTarget) {
      this.hideButtonTarget.classList.remove("hidden")
    }
  }

  localClosedValue() {
    // store default from page html locally if local version doesn't exist
    if (!localStorage.getItem(`${this.localNameValue}`)) {
      localStorage.setItem(`${this.localNameValue}`, this.closedValue)
    }

    return localStorage.getItem(`${this.localNameValue}`) === "true"
  }
}
