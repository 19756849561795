/**
 * Client-side behavior for notifications
 */

import {Controller} from "@hotwired/stimulus";
import * as NotificationApi from "../lib/api/notifications";
import Flash from "../lib/flash";

export default class extends Controller {
  static values = {
    id: Number,
    read: Boolean,
    context: String
  };

  /**
   * actions
   */

  toggle(event) {
    const toggleToRead = event.target.checked;
    this.toggleNotificationReadStatus(toggleToRead);
  }

  handleNotificationClick() {
    const toggleToRead = true;
    this.toggleNotificationReadStatus(toggleToRead);
  }

  async toggleNotificationReadStatus(toggleToRead) {
    const now = new Date();

    const newNotificationReadAt = toggleToRead ?
      now.toISOString() :
      null;

    const response = await NotificationApi.update({
      id: this.idValue,
      readAt: newNotificationReadAt
    });

    if (!response.success)
      Flash.addFlash("Sorry, we weren't able update that notification", "error");

    if (this.contextValue == 'dropdown') {
      this.removeNotificationElement();
    } else {
      if (toggleToRead) {
        this.applyReadClasses();
      } else {
        this.applyUnreadClasses();
      }
    }

    if (response.data.showBadge) {
      document.getElementById("inbox_badge").classList.toggle("hidden", false);
    } else {
      document.getElementById("inbox_badge").classList.toggle("hidden", true);
    }
  }

  toggleReadStyle() {
    this.applyReadClasses();
  }

  /**
   * helpers
   */

  applyReadClasses() {
    this.element.classList.toggle("opacity-50", true);
  }

  applyUnreadClasses() {
    this.element.classList.toggle("opacity-50", false);
  }

  removeNotificationElement() {
    this.element.remove();
  }
}
