import {Controller} from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import lodash from "lodash";
import {setInnerHtmlWithTimeout} from "../../lib/util";

export default class extends Controller {
  static targets = ["fieldSaveResult"];

  static values = {fieldName: String, hexId: String};

  /**
   * lifecycle
   */

  connect() {
    this.saveField = lodash.debounce((hexId, newValue) => {
      fetch(`/assignments/${hexId}`, {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": Rails.csrfToken(),
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify({
          assignment: {
            [this.fieldNameValue]: newValue
          }
        })
      })
      .then(res => {
        if (res.status < 300) {
          this.setFieldSaveResult(`
            <p class="mt-2 text-concert-400 text-xs">
              Saved
            </p>
        `);
        } else {
          this.setFieldSaveResult(`
            <p class="mt-2 text-crimson-400 text-xs">
              Error - please reload
            </p>
          `);
        }
      })
      .catch(error => {
        this.setFieldSaveResult(`
         <p class="mt-2 text-crimson-400 text-xs">
            Error - please reload
          </p>
        `);
      });
    }, 200);
  }

  /**
   * actions
   */

  autoSubmitField(e) {
    const newValue = e.target.value;
    const hexId = this.hexIdValue;

    this.saveField(hexId, newValue);
  }

  /**
   * helpers
   */

  setFieldSaveResult(html) {
    if (this.lastTimeout) {
      clearTimeout(this.lastTimeout);
    }

    this.lastTimeout = setInnerHtmlWithTimeout(
      this.fieldSaveResultTarget,
      html,
      3000
    );
  }

}
