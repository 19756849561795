import {Controller} from "@hotwired/stimulus";
import * as IosSignUpsApi from "../../lib/api/ios/sign_ups";

export default class extends Controller {
  static targets = ["submit", "submitContainer"];

  /**
   * actions
   */

  async submit(event) {
    event.preventDefault();

    this.applyLoadingOverlay(this.submitTarget, this.submitContainerTarget);

    try {
      const result = await IosSignUpsApi.create(this.form);

      if (result.success) {
        const formData = this.form;

        window.webkit?.messageHandlers?.nativeApp?.postMessage({
          name: "purchase",
          data: {
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            plan: formData.providerId
          }
        });

      } else if (!result.success && result.text) {
        Turbo.renderStreamMessage(result.text);

      } else {
        alert("An unknown error occurred. Please try again later");  
      }

    } catch (error) {
      alert("An unknown error occurred. Please try again later");
      throw error;
    }
  }

  /**
   * helpers
   */

  get form() {
    return {
      firstName: this.element.querySelector("form input#sign_up_first_name").value,
      lastName: this.element.querySelector("form input#sign_up_last_name").value,
      email: this.element.querySelector("form input#sign_up_email").value,
      providerName: this.element.querySelector("form input#sign_up_provider_name").value,
      providerId: this.element.querySelector("form input#sign_up_provider_id").value
    };
  }

  applyLoadingOverlay(button, container) {
    const rect = button.getBoundingClientRect();
    const computedStyle = window.getComputedStyle(button);
    const computedBackground = computedStyle.background;

    const overlay = document.createElement("div");
    overlay.className = "absolute top-0 left-0 z-20 flex items-center justify-center rounded bg-primary";
    overlay.style.width = `${rect.width}px`;
    overlay.style.height = `${rect.height}px`;
    overlay.style.background = computedBackground;

    overlay.innerHTML = `
    <div data-turbo-cache="false" class="w-full flex justify-center align-center text-concert-200">
      <svg class="animate-spin h-5 w-5 text-fill" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>    
    </div>
    `;

    container.appendChild(overlay);
  }
}
