/**
 * Attaches behavior to file selection form fields to validate
 * against file type and size limits. Note that this is front-
 * end only behavior and this should be checked separately on
 * the server after upload.
 */

import {Controller} from "@hotwired/stimulus";
import Flash from "../../lib/flash";
import Files from "../../lib/files";
import e from "trix";

export default class extends Controller {
  static targets = ["input", "dragArea", "preview"];

  static values = {
    protectSizeMb: Number,
    protectFileTypes: String,
    protectFileTypesHumanized: String
  };

  /**
   * actions
   */
  verifyFileSelection(event) {
    if (this.inputTarget.files.length > 0) {
      const file = this.inputTarget.files[0];

      const {success, message} = Files.verifyAttachment(file, {
        fileTypes: this.protectFileTypesValue,
        fileTypesHumanized: this.protectFileTypesHumanizedValue,
        maxSizeMb: this.protectSizeMbValue
      });

      if (!success) {
        Flash.addFlash(message, "error");

        this.inputTarget.value = null;

        // prevent other events that could be attached from completing. IE auto-upload on change 
        event.preventDefault();
        event.stopImmediatePropagation();
      } else {
        console.log("success");
      }
    }
  }

  fileChanged() {
    const file = this.inputTarget.files[0];
    if (file) {
      this.showPreview(file);
    }
  }

  showPreview(file) {
    const reader = new FileReader();
    reader.onload = event => {
      this.previewTarget.src = event.target.result;
      this.previewTarget.classList.remove("opacity-0");
    };
    reader.onerror = error => {
      console.error("Error occurred reading file:", error);
    };
    reader.readAsDataURL(file);
  }
}
