/**
 * Some utilities to help with the global modal system
 */

import {enter, leave} from "el-transition";
import * as Util from "../lib/util";

function show(modalId, options = {}) {
  /**
   * Opens a modal with the specified id
   * 
   * Available options
   * afterOpen: a callback function to be called after modal has opened
   */

  const state = new State();

  let modalContent = document.querySelector(`[data-modal-content-id="${modalId}"]`);

  if (!modalContent) {
    console.error(`modal controller: expected modal button (modal-id: ${modalId}) to have matching content`);
    return;
  }

  if (state.currentId == modalId) {
    console.error(`attempt to show the same modal twice was prevented id: ${modalId}`);
    return;
  }

  // put the modal into the modal container
  const clonedContent = modalContent.cloneNode(true);
  clonedContent.setAttribute("data-turbo-cache", false);
  state.content.replaceChildren(clonedContent);

  // add event listeners to for submit buttons in the content since they are being moved
  // out of the form to a new place in the dom
  clonedContent.querySelectorAll("[data-submit-form-with-action]").forEach(elem => {
    elem.addEventListener("click", e => {
      const formAction = elem.dataset.submitFormWithAction;
      const form = document.querySelector(`form[action="${formAction}"]`);

      if (form) {
        // click on the original submit button in the form from the original modal context
        form.querySelector("input[type=submit]").click();
      } else {
        console.error(`modal controller: unable to find a form with action ${formAction} to submit as for a modal button`);
      }
    });
  });

  // show content in modal container
  clonedContent.classList.toggle("hidden", false);

  enter(state.overlay).then(() => {
    enter(state.container);
    state.root.setAttribute("data-modal-shown", true);
    state.root.setAttribute("data-current-id", modalId);

    if (options.afterOpen) {
      options.afterOpen();
    }
  });

  Util.disableScroll();
}

function hide() {
  /**
   * Hides the currently open modal
   */

  const state = new State();

  if (state.container) {
    leave(state.container)
      .then(() => {
        state.content.innerHTML = "";
      });
  }


  if (state.overlay) {
    leave(state.overlay)
      .then(() => {
        state.root.setAttribute("data-modal-shown", false);
        state.root.setAttribute("data-current-id", "");
      });
  }

  Util.enableScroll();
}

class State {
  get container() {
    return document.querySelector("div[data-modal-target='container']"); 
  }
  get overlay() {
    return document.querySelector("div[data-modal-target='overlay']"); 
  }
  get overlayBackground() {
    return document.querySelector("div[data-modal-target='overlayBackground']");  
  }
  get root() {
    return document.querySelector("[data-controller*='modal']"); 
  }
  get content() {
    return document.querySelector("div[data-modal-target='content']"); 
  }
  get hideButton() {
    return this.container.querySelector("button[data-action='click->modal#hide']"); 
  }
  get shown() {
    return this.root?.getAttribute("data-modal-shown") == "true"; 
  }
  get currentId() {
    return this.root && this.root.getAttribute("data-current-id"); 
  }
}

export default {
  show,
  hide,
  State
};
