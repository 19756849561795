import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trigger", "appendTo", "loadingIndicator"];
  static values = {pageFetchUrl: String};

  triggerTargetConnected(element) {
    const options = {threshold: 0.1};
    
    this.observer = new IntersectionObserver(
      this.handleIntersection.bind(this),
      options
    );
    
    this.observer.observe(element);
  }

  handleIntersection(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.fetchNewPage();
      }
    });
  }

  fetchNewPage() {
    if (this.isFetching || this.isDisabled)
      return;

    this.isFetching = true;

    const nextPage = this.currentPage + 1;
    const url = `${this.pageFetchUrlValue}?page=${nextPage}`;
    const appendTo = this.appendToTarget;

    this.applyLoading();

    fetch(url, {method: "GET", headers: {"Accept": "application/json"}})
      .then(res => {
        return res.json();
      }).then(data => {

        this.removeLoading();

        if (data.exists) {
          appendTo.insertAdjacentHTML("beforeend", data.page_html);
          this.currentPage = nextPage;
        } else {
          this.disable();
        }

        this.isFetching = false;

      }).catch(error => {
        this.removeLoading();
        this.isFetching = false;

        console.dir(error);
      });
  }

  // Call this when there's no more pages
  disable() {
    this.element.setAttribute("data-is-disabled", "true");
  }

  applyLoading() {
    this.loadingIndicatorTarget.classList.toggle("hidden", false);
  }

  removeLoading() {
    this.loadingIndicatorTarget.classList.toggle("hidden", true);
  }

  get isDisabled() {
    return this.element.getAttribute("data-is-disabled") == "true";
  }

  get isFetching() {
    return !!this.element.getAttribute("data-is-fetching") == "true";
  }

  set isFetching(value) {
    this.element.setAttribute("data-is-fetching", !!value);
  }

  get currentPage() {
    return new Number(this.element.getAttribute("data-current-page"));
  }

  set currentPage(value) {
    this.element.setAttribute("data-current-page", value);
  }
}