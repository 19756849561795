import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="inbox--notification-section"
export default class extends Controller {
    static targets = ["singleNotification", "emptyState"];

    singleNotificationTargetConnected() {
        this.checkForIndividualNotifications();
    }

    singleNotificationTargetDisconnected() {
        this.checkForIndividualNotifications();
    }

    checkForIndividualNotifications() {
        if (this.hasSingleNotificationTarget) {
            this.emptyStateTarget.classList.add("hidden");
        } else {
            this.emptyStateTarget.classList.remove("hidden");
        }
    }
}