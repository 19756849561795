/**
 * Client-side behavior for the manage billing button.
 * - Note: This is mainly to manually VISIT the external stripe URL instead of
 *   following the redirect URL because that doesn't play nice with Turbo Native.
 *   IE This is to ensure billing portal / checkout opens in the in-app (ios) browser.
 */

import { Controller } from "@hotwired/stimulus";
import * as CheckoutsApi from "../../lib/api/checkouts";
import * as PortalSessionsApi from "../../lib/api/billing/portal_sessions";

export default class extends Controller {
  static values = {
    planId: Number,
    priceName: String,
    isCheckout: Boolean,
    source: String
  };

  declare readonly planIdValue: number;
  declare readonly priceNameValue: string;
  declare readonly isCheckoutValue: boolean;
  declare readonly sourceValue: string;

  async redirect(event) {
    event.preventDefault();

    if (this.isCheckoutValue) {
      await this.redirectToCheckout(
        this.planIdValue,
        this.priceNameValue,
        this.sourceValue
      );
    } else {
      await this.redirectToBillingPortal();
    }
  }

  /**
   * helpers
   */

  async redirectToCheckout(planId: number, priceName: string, source: string) {
    const checkout = await CheckoutsApi.create({
      plan_id: planId,
      return_url: window.location.href,
      price_name: priceName,
      source: source
    });

    if (checkout.success) {
      const url = checkout.data?.url

      if (!url)
        alert("Sorry, we weren't able to process your request. Please reach out to support or try again later.");

      Turbo.visit(url);

    } else {
      alert("Sorry, we weren't able to process your request. Please reach out to support or try again later.");
    }
  }

  async redirectToBillingPortal() {
    const session = await PortalSessionsApi.create();

    if (session.success) {
      const url = session.data?.url

      if (!url)
        alert("Sorry, we weren't able to process your request. Please reach out to support or try again later.");

      Turbo.visit(url);

    } else {
      alert("Sorry, we weren't able to process your request. Please reach out to support or try again later.");
    }
  }
}
