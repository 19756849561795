import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["field"];

    navigate() {
        let selectedOption = this.fieldTarget.options[this.fieldTarget.selectedIndex];
        let newPath = selectedOption.value;
        Turbo.visit(newPath, {action: 'replace'});
    }
}
