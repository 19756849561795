import {Controller} from "@hotwired/stimulus";
import {enter, leave} from "el-transition";

// Connects to data-controller="inbox--read-section"
export default class extends Controller {
    static targets = ["buttonText", "plusIcon", "minusIcon", "contentSection"];

    static values = {
        closed: Boolean
    }

    toggle() {
        if (this.isClosed()) {
            enter(this.contentSectionTarget)
            .then(() => this.showReadSection());
        } else {
            leave(this.contentSectionTarget)
            .then(() => this.hideReadSection());
        }
    }

    hideReadSection() {
        this.closedValue = !this.isClosed();
        this.plusIconTarget.classList.remove("hidden");
        this.minusIconTarget.classList.add("hidden");
        this.buttonTextTarget.innerText = "Show Read Notifications";
    }

    showReadSection() {
        this.closedValue = !this.isClosed();
        this.plusIconTarget.classList.add("hidden");
        this.minusIconTarget.classList.remove("hidden");
        this.buttonTextTarget.innerText = "Hide Read Notifications";
    }

    isClosed() {
        return this.closedValue;
    }
}