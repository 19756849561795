/**
 * Module that helps to submit API requests for team storage checks
 */

import Rails from "@rails/ujs";

/**
 * Returns true or false to indicate if a user can upload given byteSize file to
 * their team taking into account team storage limit.
 */
export async function canUploadFile({byteSize}) {
  const url = `/team/storage_checks?addition=${byteSize}`;

  const res = await fetch(url, {
    method: "GET",
    headers: {
      "X-CSRF-Token": Rails.csrfToken()
    }
  });

  return !!res.ok;
}
