/**
 * Adds client side behavior to rich text content generated by trix
 */

import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (this.element) {
      this.addTargetToLinks();

      // add a mutation observer so that any change to DOM will re-run desired effects
      const observer = new MutationObserver(() => {
        this.addTargetToLinks();
      });

      observer.observe(this.element, {subtree: true, childList: true});
    }
  }

  /**
   * helpers
   */

  addTargetToLinks() {
    this
      .element
      ?.querySelectorAll("a")
      ?.forEach(element => {
        if (element.getAttribute("target") == null) {
          element.setAttribute("target", "_blank");
        }

        element.setAttribute("data-turbo", false);
      });
  }
}
