import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "link", "panel"];

  connect() {
    const params = new URLSearchParams(window.location.search);

    if (params.get("tab") && this.trackSelection)
      this.switchToTab(params.get("tab"));
  }

  switchToThis(event) {
    event.preventDefault();
    const targetPaneId = event.currentTarget.dataset.targetId;
    this.switchToTab(targetPaneId);
  }

  /**
   * helpers
   */

  switchToTab(targetPaneId) {
    this.tabTargets.forEach(element => {
      if (element.dataset.targetId === targetPaneId) {
        element.classList.add("border-action-300");
        element.classList.add("text-action-300");
        element.classList.remove("border-transparent");
        element.classList.remove("text-action-400");
        element.classList.remove("not-only:hover:border-action-200");
      } else {
        element.classList.remove("border-action-300");
        element.classList.remove("text-action-300");
        element.classList.add("border-transparent");
        element.classList.add("text-action-400");
        element.classList.add("not-only:hover:border-action-200");
      }
    });

    this.linkTargets.forEach(element => {
      if (element.dataset.targetId === targetPaneId) {
        element.classList.add("active");
        element.classList.add("border-concert-300");
        element.classList.add("text-concert-300");
        element.classList.remove("text-concert-400");
      } else {
        element.classList.remove("active");
        element.classList.remove("border-concert-300");
        element.classList.remove("text-concert-300");
        element.classList.add("text-concert-400");
      }
    });

    this.panelTargets.forEach(element => {
      if (element.dataset.panelId === targetPaneId) {
        element.classList.toggle("hidden", false);
      } else {
        element.classList.toggle("hidden", true);
      }
    });

    if (this.trackSelection)
      this.updateBrowserHistory(targetPaneId);
  }

  updateBrowserHistory(panelId) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set("tab", panelId);
    const newUrl = `${window.location.origin}${window.location.pathname}?${urlSearchParams.toString()}`;

    window.history.pushState({}, "", newUrl);
  }

  get trackSelection() {
    return this.element.getAttribute("data-track-selection") == "true";
  }
}
