/**
 * Helper methods for working with files
 */

import * as TeamStorageChecksApi from "./api/teams/storage_checks";

function verifyAttachment(file, {fileTypes, fileTypesHumanized, maxSizeMb}) {
  const response = {success: false, message: null};

  const acceptedTypes = fileTypes.split(", ");

  if (!acceptedTypes.includes(file.type)) {
    response.success = false;
    response.message = `That file type isn't supported in this field. ${fileTypesHumanized} files up to ${maxSizeMb} MB are allowed as attachments.`;

    return response;
  }

  if (maxSizeMb != null) {
    const maxFileSize = maxSizeMb * 1000000;

    // check file size according to max upload size
    if (file.size > maxFileSize) {
      response.success = false;
      response.message = `Attachments in this field must be smaller than ${maxSizeMb} MB.`;

      return response;
    }
  }

  response.success = true;

  return response;
}

async function verifyTeamHasStorageSpace(file) {
  return await TeamStorageChecksApi.canUploadFile({byteSize: file.size});
}

export default {
  verifyAttachment,
  verifyTeamHasStorageSpace
};
