/**
 * Allows the Cloudinary player to be interacted via a consistent API
 */

export default class CloudinaryPlayerAdapter {
  constructor(playerContainer, providerId, version, cloudName) {
    this.videoElement = document.createElement("video");
    this.videoElement.className = "cld-video-player w-full h-full";

    const container = document.createElement("div");
    container.className = "w-full h-full";
    container.setAttribute("data-turbo-cache", "false");

    container.appendChild(this.videoElement);
    playerContainer.appendChild(container);

    this.player = cloudinary.videoPlayer(
      this.videoElement, {
        publicId: providerId,
        version: version,
        cloud_name: cloudName,
        sourceTypes: ["mp4"],
        transformation: {
          crop: "fit",
          width: 1920,
          height: 1080,
          quality: 70
        },
        controls: true,
        showBigPlayButton: true,
        autoplay: true,
        colors: {
          accent: "#fff200"
        },
        showLogo: false
      }
    );
  }

  play() {
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  togglePlayState() {
    if (this.player.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  setPosition(seconds) {
    this.player.currentTime(seconds);
  }

  onPlay(listener) {
    this.player.on("play", listener);
  }

  onPause(listener) {
    this.player.on("pause", listener);
  }

  onTimeupdate(listener) {
    this.player.on("timeupdate", () => {
      const seconds = Number(this.videoElement.currentTime);
      listener(seconds);
    });
  }

  isFocused() {
    return document.activeElement == this.videoElement;
  }

  handlesSpaceBarEvents() {
    return true;
  }
}
