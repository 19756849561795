import {Controller} from "@hotwired/stimulus";
import Swiper from "swiper";
import {Navigation, Pagination, EffectCoverflow} from "swiper";

export default class extends Controller {
  static targets = ["next", "prev"];

  static values = {
    initialSlide: Number
  };

  connect() {
    new Swiper(this.element, {
      speed: 200,
      spaceBetween: 24,
      initialSlide: this.initialSlideValue,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
        disabledClass: "opacity-50"
      },
      centeredSlides: true,
      effect: "coverflow",
      grabCursor: true,
      slidesPerView: "auto",
      coverflowEffect: {
        scale: 0.7,
        rotate: 0,
        depth: 100,
        modifier: 1,
        slideShadows: false
      },
      modules: [Navigation, Pagination, EffectCoverflow]
    });
  }
}
