/**
 * Module that helps to submit API requests for board stages
 */

import Rails from "@rails/ujs";

export async function sendDropUpdate({boardHexId, stageHexId, order}) {
  const url = `/boards/${boardHexId}/stages/${stageHexId}/order`;

  const res = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Accept": "text/vnd.turbo-stream.html",
      "X-CSRF-Token": Rails.csrfToken()
    },
    body: JSON.stringify({
      stage: {
        order
      }
    })
  });

  if (res.ok || res.status == 422)
    return await res.text();

  throw new Error(`Unable to complete sendDropUpdate. The server responded ${res.status}`);
}
