/**
 * Merely toggles a class for more expensive looking file uploads
 *
 */

import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dragArea", "input"];

  dragOver() {
    this.dragAreaTarget.classList.add("drag-over");
  }

  dragLeave() {
    this.dragAreaTarget.classList.remove("drag-over");
  }

  drop() {
    this.dragAreaTarget.classList.remove("drag-over");
  }
}