/**
 * Controls in-app parties (ie confetti animations)
 *
 * Prepare for confetti on load with these data elements:
 *
 * data-controller="confetti"
 * data-confetti-target="party"
 * data-immediate="true"
 *
 */

import {Controller} from "@hotwired/stimulus";
import Confetti from "../lib/confetti.build";

export default class extends Controller {
  static targets = ["party"];

  /**
   * lifecycle
   */

  partyTargetConnected(element) {
    const immediate = element.getAttribute("data-immediate") == "true";

    if (immediate) {
      const id = this.ensureElementId(element);

      let confetti = new Confetti(id.toString());

      confetti.setCount(500);
      confetti.setSize(1);
      confetti.setPower(25);
      confetti.setFade(false);
      confetti.destroyTarget(false);

      // get middle of element
      const rect = element.getBoundingClientRect();
      const x = rect.left + (rect.width / 2);
      const y = rect.top + (rect.height / 2);

      setTimeout(() => {
        var clickEvent = new MouseEvent("click", {clientX: x, clientY: y});
        element.dispatchEvent(clickEvent);
      }, 100);
    }
  }

  /**
   * helpers
   */

  ensureElementId(element) {
    if (element.id)
      return element.id;

    const id = Math.floor(Math.random() * 100000);
    element.id = id;

    return id;
  }
}
