/**
 * Module that helps to submit API requests for board cards
 */

import Rails from "@rails/ujs";

export async function sendDropUpdate({boardHexId, cardHexId, order, stageHexId = null, context = null}) {
  const url = `/boards/${boardHexId}/cards/${cardHexId}/order`;
  
  let payload = {};

  if (context)
    payload.context = context;

  if (stageHexId) {
    payload.card = {
      order,
      stage_hex_id: stageHexId,
      context
    };
  } else {
    payload.card = {order};
  }

  const res = await fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "Accept": "text/vnd.turbo-stream.html",
      "X-CSRF-Token": Rails.csrfToken()
    },
    body: JSON.stringify(payload)
  });

  if (res.ok || res.status == 422)
    return await res.text();

  throw new Error(`Unable to complete sendDropUpdate. The server responded ${res.status}`);
}
