import { Controller } from "@hotwired/stimulus";
import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import { Mark } from "@tiptap/core";

const DelMark = Mark.create({
  name: 'del',

  parseHTML() {
    return [
      { tag: 'del' },
    ];
  },

  renderHTML() {
    return ['del', 0];
  },

  addCommands() {
    return {
      toggleDel: () => ({ commands }) => {
        return commands.toggleMark(this.name);
      },
    };
  },
});

export default class extends Controller {
  static targets = ["content", "input"];

  connect() {
    console.log("tiptap is connected!");

    this.editor = new Editor({
      element: this.contentTarget,
      extensions: [
        StarterKit.configure({
          bulletList: true,
          orderedList: true,
          bold: true,
          italic: true,
          strike: false, // use custom DelMark instead
          blockquote: true,
        }),
        TextStyle,
        DelMark,
      ],
      content: this.inputTarget.value,
      onUpdate: ({ editor }) => {
        this.inputTarget.value = this.editor.getHTML();
      },
    })
  }

  toggleBold() {
    this.editor.chain().focus().toggleBold().run();
  }

  toggleItalic() {
    this.editor.chain().focus().toggleItalic().run();
  }

  toggleStrike() {
    this.editor.chain().focus().toggleDel().run();
  }

  toggleBulletList() {
    this.editor.chain().focus().toggleBulletList().run();
  }

  toggleOrderedList() {
    this.editor.chain().focus().toggleOrderedList().run();
  }

  toggleBlockquote() {
    this.editor.chain().focus().toggleBlockquote().run();
  }

  disconnect() {
    this.editor.destroy();
  }
}
