/**
 * Controls a combobox that is rendered on the client.
 * 
 * - Why? Sometimes turbo confuses frames & stream orders when dealing with fast sequential requests.
 *   Rending on the client means we can take more of a heavy hand when rendering to avoid some of
 *   those issues and degraded experience. 
 */

import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";
import KeyNavigatedList from "../../lib/key_navigated_list";
import { debounce } from "lodash";
import Flash from "../../lib/flash";

export default class extends Controller {
  static targets = ["panel", "input", "results", "turboFrame"];

  static values = {
    url: String
  };

  declare readonly panelTarget: HTMLElement;
  declare readonly resultsTarget: HTMLElement;
  declare readonly inputTarget: HTMLInputElement;
  declare readonly turboFrameTarget: any;

  declare readonly urlValue: string;

  list: KeyNavigatedList;
  submit: () => Promise<void>;

  /**
   * lifecycle
   */

  connect(): void {
    this.list = new KeyNavigatedList(
      this.panelTarget,
      this.panelTarget, {onChoose: this.chooseResult.bind(this)}
    );

    this.submit = debounce(this.submitSearch.bind(this), 300);

    this.element.setAttribute("data-is-open", "false");
  }

  /**
   * actions
   */

  start() {
    this.submitSearch();
    enter(this.panelTarget).then(() => this.element.setAttribute("data-is-open", "true"));
  }

  stop() {
    leave(this.panelTarget).then(() => {
      this.element.setAttribute("data-is-open", "false");
    });
  }

  handleKeyDown(event) {
    if (event.key == "Escape") {
      this.stop();
      event.preventDefault();
      return;
    }

    this.list.handleKeyDown(event);
  }

  handleDocumentClick(event) {
    const path = event.composedPath();

    if (!path.includes(this.element) && this.isOpen) {
      this.stop();
    }
  }

  handleResultClick(event: MouseEvent) {
    this.chooseResult(event.currentTarget as HTMLElement);
    event.preventDefault();
    event.stopPropagation();
  } 

  /**
   * helpers
   */

  get isOpen(): boolean {
    return this.element.getAttribute("data-is-open") == "true";
  }

  submitSearch() {
    const url = new URL(this.urlValue);
    url.searchParams.append("query", this.searchQuery);
    const turboFrameSrc = url.toString();

    this.turboFrameTarget.setAttribute("src", turboFrameSrc);
  }

  async chooseResult(element: HTMLElement, options: {selectNext: boolean} = {selectNext: true}) {
    const {selectNext} = options;
    const behavior = element.getAttribute("data-on-choose-behavior");

    if (behavior == "api") {
      const payload = element.getAttribute("data-api-payload");
      const endpoint = element.getAttribute("data-api-endpoint");
      const isTurboStream = element.getAttribute("data-api-is-turbo-stream") == "true";
      const errorMessage = element.getAttribute("data-api-error-message");
      const method = element.getAttribute("data-api-method") || "POST";

      if (element.getAttribute("data-api-request-active") != "true") {
        element.setAttribute("data-api-request-active", "true"); // prevent duplicate requests

        try {
          element
            .querySelectorAll(".loading")
            .forEach(loadingElement => loadingElement.classList.toggle("hidden", false));

          const fetchParams = {
            method: method,
            headers: {
              "X-CSRF-Token": Rails.csrfToken(),
              "Accept": isTurboStream ? "text/vnd.turbo-stream.html" : "application/json",
              "Content-Type": "application/json",
            }
          }

          if (method != "GET")
            fetchParams.body = payload;

          const response = await fetch(endpoint, fetchParams);

          if (!response.ok)
            throw new Error();

          if (isTurboStream) {
            const streamHtml = await response.text();
            Turbo.renderStreamMessage(streamHtml);
          }

          element
            .querySelectorAll(".loading")
            .forEach(loadingElement => loadingElement.classList.toggle("hidden", true));

          element.setAttribute("data-api-request-active", "false");

        } catch (error) {
          element
            .querySelectorAll(".loading")
            .forEach(loadingElement => loadingElement.classList.toggle("hidden", true));

          element.setAttribute("data-api-request-active", "false");

          Flash.addFlash(errorMessage, "error");
        }
      }

    } else if (behavior == "single_select") {
      // replace form field
      const formFieldId = element.getAttribute("data-single-select-form-field-target");
      const formFieldValue = element.getAttribute("data-single-select-value");
      document.querySelector(`input#${formFieldId}`)?.setAttribute("value", formFieldValue || "");

      // replace html
      const htmlId = element.getAttribute("data-single-select-html-target");
      const html = element.getAttribute("data-single-select-html");
      const htmlTargetELement = document.querySelector(`#${htmlId}`)

      if (htmlTargetELement)
        htmlTargetELement.innerHTML = html || "";

      // close panel
      this.stop();

    } else if (behavior == "multi_select") {
      // append to form field target
      const formAppendId = element.getAttribute("data-multi-select-form-html-append-target") || "";
      const formHtml = element.getAttribute("data-multi-select-form-html") || "";

      const formAppendElement = document.getElementById(formAppendId);

      if (!formAppendElement)
        throw new Error(`formAppendElement #${formAppendId} can't be found`);

      formAppendElement.insertAdjacentHTML("beforeend", formHtml);

      // append to html
      const htmlAppendId = element.getAttribute("data-multi-select-html-append-target") || "";
      const html = element.getAttribute("data-multi-select-html") || "";

      const htmlAppendElement = document.getElementById(htmlAppendId);

      if (!htmlAppendElement)
        throw new Error(`htmlAppendElement #${htmlAppendId} can't be found`);

      htmlAppendElement.insertAdjacentHTML("beforeend", html);

      // remove result from panel
      element.remove();

      // add to search form for submittal to search endpoint
      const signedGlobalId = element.getAttribute("data-multi-select-entity-signed-global-id");
      const clientComboboxInputId = element.getAttribute("data-multi-select-client-combobox-input-id");
      const inputHtml = `<input id='${clientComboboxInputId}' type='hidden' name='currently_selected[]' value='${signedGlobalId}'>`;
      this.element.insertAdjacentHTML("beforeend", inputHtml);

      // close panel
      this.stop();

    } else {
      throw new Error(`Unknown onChoose behavior ${behavior}`)
    }

    if (selectNext) {
      const next = this.list.getNextSelectableSibling(element);

      if (next) {
        this.list.select(next);
      } else {
        const prev = this.list.getPreviousSelectableSibling(element);

        if (prev)
          this.list.select(prev);
      }
    }
  }

  get searchQuery(): string {
    return this.inputTarget.value || "";
  }
}
