
import {Controller} from "@hotwired/stimulus";
import Sortable from "sortablejs";
import Rails from "@rails/ujs";

window.Rails = Rails;

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    });
  }

  end(event) {
    let id = event.item.dataset.id;
    let data = new FormData();
    data.append("position", event.newIndex + 1);

    fetch(this.data.get("url").replace(":id", id), {
      method: "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken()
      },
      body: JSON.stringify({playlist_order: {position: event.newIndex + 1}})

    }).then(res => {
      if (!res.ok) {
        alert("The order update couldn't be saved. Please reload the page and try again.");
      }

    }).catch(error => {
      console.error(error);

      alert("The order update couldn't be saved. Please reload the page and try again.");
    });
  }
}
