/**
 * Module that contains behavior for filter toggle buttons
 */

import * as Util from "./util";

function toggle(element, valueFormElement, updateElementState = true) {
  if (!element) {
    console.error("filter_toggle.toggle: element is required");
    return false;
  }

  if (!valueFormElement) {
    console.error("filter_toggle.toggle: valueFormElement is required");
    return false;
  }

  const filterName = element.getAttribute("data-filter-value");
  const currentFilters = valueFormElement.value.split(",");
  const contextToSet = element.getAttribute("data-set-context");

  if (currentFilters.includes(filterName)) {
    const index = currentFilters.findIndex(element => element == filterName);
    currentFilters.splice(index, 1);

    if (updateElementState)
      disable(element);

  } else {
    currentFilters.push(filterName);

    if (updateElementState)
      enable(element);
  }

  const serializedFilters = currentFilters
    .filter(filter => filter)
    .join(",");

  valueFormElement.value = serializedFilters;

  if (contextToSet)
    Util.setContext(currentFilters.length > 0 ? contextToSet : null);

  return true;
}

function updateFormElement({element, filterName, enabled}) {
  const currentFilters = element.value.split(",");

  if (enabled && !currentFilters.includes(filterName)) {
    currentFilters.push(filterName);

  } else if (!enabled && currentFilters.includes(filterName)) {

    const index = currentFilters.findIndex(element => element == filterName);
    currentFilters.splice(index, 1);
  }

  const serializedFilters = currentFilters
    .filter(filter => filter)
    .join(",");

  element.value = serializedFilters;
}

function enable(element) {
  const highlightColor = element.getAttribute("data-highlight-color");

  element.classList.toggle(`bg-${highlightColor}`, true);
  element.classList.toggle("bg-transparent", false);
  element.classList.toggle("border-concert-600", false);
  element.classList.toggle(`border-${highlightColor}`, true);
  element.classList.toggle("opacity-70", false);
}

function disable(element) {
  const highlightColor = element.getAttribute("data-highlight-color");

  element.classList.toggle(`bg-${highlightColor}`, false);
  element.classList.toggle("bg-transparent", true);
  element.classList.toggle("border-concert-600", true);
  element.classList.toggle(`border-${highlightColor}`, false);
  element.classList.toggle("opacity-70", true);
}

export default {
  toggle,
  updateFormElement,
  enable,
  disable
};
