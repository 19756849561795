/**
 * Allows the vimeo player to be interacted via a consistent API
 */

import * as Util from "../util";
import Player from "@vimeo/player";

export default class VimeoPlayerAdapter {
  constructor(sourceId) {
    const playerElement = document.getElementById(`vimeo_player_${sourceId}`);
    this.iframe = playerElement.querySelector("iframe");

    this.player = new Player(
      this.iframe, {
        id: sourceId,
        responsive: true
      }
    );

    // Need to add some listeners to prevent iFrame from stealing focus
    this.onPause(() => Util.focusWindow());
    this.onVolumeChange(() => Util.focusWindow());
    this.onPlaybackRateChange(() => Util.focusWindow());
  }

  play() {
    this
      .player
      .play()
      .catch(() => console.log("We tried to auto play the video but your browser settings prevented it."));
  }

  pause() {
    this.player.pause();
  }

  togglePlayState() {
    this
      .player
      .getPaused()
      .then(paused => paused ? this.play() : this.pause());
  }

  setPosition(seconds) {
    this.player.setCurrentTime(seconds);
  }

  onPlay(listener) {
    this.player.on("play", listener);
  }

  onPause(listener) {
    this.player.on("pause", listener);
  }

  onTimeupdate(listener) {
    this.player.on("timeupdate", data => {
      const seconds = Number(data.seconds);
      listener(seconds);
    });
  }

  onVolumeChange(listener) {
    this.player.on("volumechange", listener);
  }

  onPlaybackRateChange(listener) {
    this.player.on("playbackratechange", listener);
  }

  isFocused() {
    return document.activeElement == this.iframe;
  }

  handlesSpaceBarEvents() {
    return false;
  }
}
