/**
 * Allows the Mux player to be interacted via a consistent API
 */

import "@mux/mux-player";

export default class MuxPlayerAdapter {
  constructor(playerElement) {
    this.element = playerElement;
  }

  play() {
    this
      .element
      .play()
      .catch(() => console.log("We tried to auto play the video but your browser settings prevented it."));
  }

  pause() {
    this.element.pause();
  }

  togglePlayState() {
    if (this.element.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  setPosition(seconds) {
    this.element.currentTime = seconds;
  }

  onPlay(listener) {
    this.element.addEventListener("play", listener);
  }

  onPause(listener) {
    this.element.addEventListener("pause", listener);
  }

  onTimeupdate(listener) {
    this.element.addEventListener("timeupdate", () => {
      const seconds = this.element.currentTime;
      listener(seconds);
    });
  }

  isFocused() {
    return document.activeElement == this.element;
  }

  handlesSpaceBarEvents() {
    return true;
  }
}
