import {Controller} from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import {throttle} from "lodash";

export default class extends Controller {
  static targets = ["results", "form", "input"];

  initialize() {
    this.search = throttle(this.search, 350).bind(this);
  }

  search() {
    Rails.fire(this.formTarget, "submit");
  }

  handleResults() {
    const [data, status, xhr] = event.detail;
    this.resultsTarget.innerHTML = xhr.response;
  }
}