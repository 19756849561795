/**
 * Client side behavior for tooltips
 */

import {Controller} from "@hotwired/stimulus";
import {enter, leave} from "el-transition";

export default class extends Controller {
  static targets = ["tip"];

  enter() {
    if (this.hasTipTarget) {
      enter(this.tipTarget);
    }
  }

  leave() {
    if (this.hasTipTarget) {
      leave(this.tipTarget);
    }
  }
}
